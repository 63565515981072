import React from 'react';
import BackgroundComponent from '../Components/bg';
import { Link } from 'react-router-dom';
const BlogDetail = () => {
    
    // Fetch the blog details using blogId or pass it to a state or context if needed

    return (
        <div className="min-h-screen relative">  
        <BackgroundComponent />
            <Link to="/Readings">
                <button className="absolute  z-50 top-5 left-5 bg-white text-blue-500 rounded-lg shadow-lg p-2 hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                    Back
                </button>
            </Link>
            <div className="absolute top-5 left-0 right-0 flex flex-col items-center">
        <div className="flex">
          <span className="block  outlined-green text-4xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-7xl font-bold font-matura text-pink-500 whitespace-nowrap">
          Readings
          </span>
          
        </div> 
        <div className="inset-0 flex flex-col mt-10  pt-10 pb-10 bg-white mx-5 sm:mx-20 xl:mx-40 text-left ">
        <p className="mx-5">Greetings, dear Soul! I, Christopher Philip Madden, a.k.a. Crispy Matters, am very pleased to offer you four varieties of Mayan Embodiment readings. </p>
        <p className="mx-5 pt-8">
        <strong>Type 1</strong> is called <strong>Core Self Reading</strong>, and costs <strong>$13.20</strong> in American funds. (All monetary quotes on this website are in U$ funds.) It includes a deep explanation of your Mayan birthday, which is your Core Self Tonal and Nagual. The Tonal is also called Tone, or some people/regions just call it Number, and is a number from 1 to 13, and the Nagual, also spelled Nawal, with a plural of Naguales and Nawales, is your Glyph or pictorial image, both of which were the Mayan date on the day you were born. Some Guatemalan regions say both your Number / Tonal / Tone AND your Glyph are your Nawales, but for the sake of simplicity and clarity, I use Tone and Glyph.

    </p>
    <p className="mx-5 pt-8">
    The Glyph has also been called your Mayan Sun, or Sun Sign, Day Seal, or Day Sign, and various other terms (which may or may not include the Tonal / Tone / Number). All these variations are because we are now communicating in English from translations of translations of translations of more than 500 years. The Maya themselves consisted of countless ethnic regions with over 100 linguistic variations for thousands of years, covering an area of about 3,000 kilometers wide. There are still many indigenous Mayan groups today with different languages, cultures, and customs in Mexico, Belize, Honduras, and Guatemala. We must remember, too, the Maya did not use the alphabet, therefore, all spellings of Mayan words are inaccurate to some degree.
    </p>
    <p className="mx-5 pt-8">
    Be that as it may, the <strong>Core Self Reading</strong> is about ten pages long, in a Word document with some graphics, and tells you all the Key Code Words for your Core Self Tone and Glyph, the Chakra connections of both Glyph and Tone, as well as your Tonal Ego Dramas and your Nagual Shadow Challenges. Your K’ een number, also spelled K’in, and/or Kin, which is your position in the 260-day Tzolk’ een calendar (often spelled as Tzolkin, and/or Tzolk’in, and/or Chol Qij, and/or Cholq’ij), is also explained.
    </p>
    <p className="mx-5 pt-8">
    <strong>Type 2:</strong> the <strong>Core Self Extended Mayan Embodiment Reading</strong> is <strong> $20.13</strong> and includes the above, plus a full 13 Month Mayan calendar, starting the month that you order the Reading, and for the following 12 months, overlaid onto the standard Gregorian calendar. That Calendar booklet is available for purchase on this website for $9.99 and also contains other explanatory graphical information, including every Key Code Word for every Tone and Glyph! A sample can be seen on the Shop page of this website. That calendar booklet, and therefore the <strong> Extended Reading</strong> contains the full 260-day Tzolkin / Tzolk’in / Tzolk’ een rectangular grid as well, all in a 300dpi PDF, perfect for printing.
    </p>
    <p className="mx-5 pt-8">
    <strong>Type 3:</strong> the <strong>Full Mayan Embodiment Reading</strong> has a sliding scale for you to choose how much you pay from <strong>$99.99</strong> up to <strong> $260</strong> and includes, in addition to the Extended reading above, a complete explanation of your four Guides as shown on the Empowerment Circle and DaVinci Codex page of this website. What I do differently for you here, is conduct a ceremony and enter a shamanic trance and then create customized <strong>High Vibration Code Phrases</strong> for each of your five Tonal and Nawal positions. For each combination of Tone and Glyph there are approximately 150 potential phrases, and from them, I feel into your chart, and your life circumstances, and create three phrases that could be potentially life changing for each of your five positions, therefore 15 golden Key Codes in all. Additionally, you will also be told of other famous people with your same Mayan birthday, and those of your four Guides.
    </p>
    <p className="mx-5 pt-8">
    In order for me to create the most personal document and customized Codes for you, the most appropriate way for me to do this is with your feedback and information first. Therefore, please answer these questions when submitting your request to 
          <a href=" mailto:mayan.embodiment.astrology@gmail.com" className="text-blue-600 underline"> mayan.embodiment.astrology@gmail.com </a>,
          knowing that they are also optional (I am very comfortable doing this Code Generation purely by intuition);

    </p>
    <div className="pt-2 pl-8">
        <ol className="mx-5 pt-5 list-decimal space-y-4 ">
            <li className="text-gray-900">What is my intention for getting a Full Embodiment Reading?</li>
            <li className="text-gray-900">What is my most obvious block to fulfillment / joy / manifesting / creating my highest timeline?</li>
            <li className="text-gray-900">What previous Shadow Work / Counselling / Therapy have I done? How effective was it? What did it not address? What areas were left unhealed?</li>
            <li className="text-gray-900">What substances (alcohol, junk food, tobacco, marijuana, cocaine, mushrooms, ayahuasca, etc.) do I frequently use? Which ones? How frequently?</li>
            <li className="text-gray-900">What trauma / issues have negatively affected my past?</li>
        </ol>
    </div>
    <p className="text-gray-900 mx-5 pt-8">
    The more open and honest you are, the higher degree of healing success you can expect from this <strong>Full Embodiment Reading</strong>, and please know this: I am a Secret Keeper: Nothing you say will EVER be repeated outside the container of our relationship. I will NEVER share any details about you with anyone.
    </p>
    <p className="text-gray-900 mx-5 pt-8">
    Now, each Tone and each Glyph of your four Guides act together to inform your Core Self of crucial information on your path to spiritual growth and Ascension, the goal of every Soul. Firstly, you will learn what Wisdom is required to be learned from your Past, then how to balance your Divine Feminine and Divine Masculine qualities, and finally how best to chart the path to your highest vibration Future Vision timeline. When those four things are harnessed and integrated, you become a <strong>Fully Embodied Core Self</strong>. This is Soul Cartography. This is spiritual growth. This is the evolution of consciousness starting with the individual, which can then spread outwards to family, lovers, and friend, and then to the community, and finally, to the world. But it all starts with you. 
    </p>
    <p className="text-gray-900 mx-5 pt-8">
    Along with the information about these four Guides and your Core Self, the Shadow Ego Challenges for each will be Soul-‘splained for you. For example, each person has specific Shadow Ego issues to overcome in order to embody their Divine Feminine powers, and so on. Therefore, how to address each one will be done face-to-face on Zoom.

    </p>
    <p className="text-gray-900 mx-5 pt-8">
    With your <strong>Full Reading</strong> I will do a one-on-one ZOOM meeting with you of approximately 90-minutes after you have received, and read, your Full Embodiment Reading and the Calendar Workbooklet. In this heart-to-heart meeting you will receive specific healing suggestions, techniques, and activations, as well as have all your questions about your document answered personally. Thus, the Zoom can only be scheduled after you have read your document so that you may ask any questions that arose during and after reading it. You will also receive a 300dpi PDF <strong>Workbooklet</strong> of over 50 pages.

    </p>
    <p className="mx-5 pt-8"><strong>What is in the Workbooklet?</strong></p>
    <p className="text-gray-900 mx-5 pt-4">
    The Workbooklet is a compilation of information about the Divine Mayan Tzolk’ een calendar gleaned from over 25 years of study, with an extreme breadth and depth of facts, information, and diagrams. It also contains 13 Months of the Mayan calendar from the month that you order your Reading. Additionally, it tells you the Key Code Words for every single Tone and Glyph, as well as their Chakra Connections, with an explanation of what the energetic meaning of each Chakra is. Thus, you will be able to grok anyone’s Mayan Embodiment, as well as any day of the year, to a very high degree. This also allows you to use the Mayan Tzolk’ een calendar as an interactive oracle, knowing which days are best for what kinds of activities, and which days (and people) might be challenging.
    </p>
    <p className="text-gray-900 mx-5 pt-4">
    At its Heart Chakra core, the Workbooklet has a deeply healing <strong>Shadow Ego Work</strong> segment, with a Clearing Karmic Contract and requisite activities which will be explained in your ZOOM with me. However, here is a link to my YouTube Channel with an explanatory example of how to do the
        <a href=" https://www.youtube.com/watch?v=CRU9IjBp5xM&t=1437s" target="_blank" className="text-blue-600 underline"> Shadow Ego Work  </a> 
        solo, if you wish to do it that way. (I will not force you to do these intensely personal activities with me, however, it is my sacred duty to help you walk through your dark inner room while holding a new, bright light to heal all that waits. Again, I am a Secret Keeper and prepared for anything.) 
        </p>
    <p className="text-gray-900 mx-5 pt-4">
    As for me, by the way, I have a Bachelor’s Degree of Psychology from Simon Fraser University in Vancouver, British Columbia, I have been through therapeutic counselling, I have addressed my own traumas, overcome my addictions to alcohol and drugs, and I have healed my generational trauma. I have deeply explored shamanic and psychedelic realms, I have a Master’s Degree in Teaching, and I will treat you with utmost respect because I deeply believe in the Mayan saying with all my heart:
    </p>
    <p className="text-gray-900 mx-5 pt-4">
    Een Lak’esh, Ala K’ een.</p>
    <p className=" text-gray-900 mx-5 pt-8">This means: I am another You, You are another Me, We are One, One God-sciousness. In brief:

</p>
    <p className="text-gray-900 mx-5 pt-4">
    
    <strong> I am You are God-sciousness.</strong>
    </p>
    <p className="text-gray-900 mx-5 pt-4">
    Additionally, the Workbooklet tells you exactly how to combine two people’s Mayan birthdays in order to find the deeply esoteric “Third Spiritual Entity”. The intricacies of how all the Mayan Glyphs relate to each other according to their Color / Direction / Body are therefore graphically laid out. This allows for a deep dive into the hidden factors in any relationship, which leads us to the fourth type of reading:
   </p>
   <p className="text-gray-900 mx-5 pt-4">
   <strong>Type 4:</strong> the <strong>Full Mayan Embodiment Combined Reading:</strong>
   </p>
   <p className="text-gray-900 mx-5 pt-4">This reading will greatly enhance your innerstanding of your most intimate relationship. It can be between any two people, for example, parent and child, or siblings, or between spouses / lovers. As the Divine Mayan Tzolk’ een came to earth from the Pleiades according to ancient Mayan myth and modern prophecy, this reading is extremely revealing Soul Cartography. However, unless conducted with one person who is, for whatever reason, unable to answer these following questions, <strong><u>both</u></strong> people should individually answer them before I begin the reading: *</p>
    <ol className="list-decimal space-y-2 text-md text-gray-900 mx-5 pt-4 pl-6">
        <li>What do you hope to achieve from getting a Full Embodiment Combined Soul Cartography Reading?</li>
        <li>What are the three most favorable qualities of the other person in this reading?</li>
        <li>What are the three most challenging qualities of the other person in this reading?</li>
        <li>What do you feel are the main blocks to intimacy / communication / understanding you have with the other person in this reading?</li>
        <li>What is the one thing you wish you could change about yourself?</li>
        <li>What is the one thing you wish you could change about the other person in this reading?</li>
    </ol>
    <p className="text-gray-900 mx-5 pt-6">
    * Again, all questions are optional to be answered, however, the most honestly and openly they are answered will raise the vibration and potential healing effects of the resulting document. Without your answers I will gladly use my intuition and still stand behind any outcome with full integrity.</p>
    <p className="font-bold text-gray-900 mx-5 pt-8">The Document:</p>
    <p className="text-gray-900 mx-5 pt-4">
    In a Combined Soul Reading Document there are three full readings; one for each individual, and the third for the “Third Spiritual Entity”, all with customized graphics and High Vibration Code phrases which can only be  derived through shamanic trance. The fourth segment of the document is an explanation of the three levels of connection: 
        </p>
    <ol className="list-decimal space-y-2 text-md text-gray-900 mx-5 pt-4 pl-6">
        <li>How Person A relates to Person B with regards to Tonal and Nagual (including Chakras).</li>
        <li>How Person A relates to the Third Spiritual Entity with regards to Tonal and Nagual (including Chakras).</li>
        <li>How Person B relates to the Third Spiritual Entity with regards to Tonal and Nagual (including Chakras).</li>
    </ol>
    <p className="text-gray-900 mx-5 pt-7">
    As you can most likely feel into, this is an extremely in-depth and complex reading, with absolutely no spiritual stones left unturned. These three documents generally takes me eight hours to complete, and requires a minimum of two hours to Soul-‘splain on Zoom, therefore the financial compensation for this reading is yours to choose between $520 and $1320. 
    </p>
    <p className="text-gray-900 mx-5 pt-7">
    This type of spiritual healing could ostensibly substitute for many years of typical clinical therapy, and in fact, it goes to a higher and deeper level of spiritual connection than most typical counselling methods currently available in the world.  
    </p>
    <p className="text-gray-900 mx-5 pt-7">
        <strong>*** Caveat </strong> : : Mayan Embodiment Astrology and its Shadow Ego Work are not substitutions to professional therapy or counselling. They could truly be all that you need to raise your vibration and create a new timeline, and yet… If you are feeling depressed or angry or frequently have other serious mood swings, or if you have seriously unhealthy addictions, please, by all means, contact a professional psychiatrist, psychologist, a licensed counsellor, therapist, or a Past Life Regression Therapist. ***
    </p>
    <p className="text-gray-900 mx-5 pt-7">
   <strong>Lastly, dear Soul, know this: I offer a 100% money back guarantee.</strong>
    </p>
    <p className="text-gray-900 mx-5 pt-7">If you feel, after reading the document and our Zoom, that this has been no use to you whatsoever, I will refund your money completely.</p>
    <p className="text-gray-900 mx-5 pt-7">
        With Unconditional Love and Universal Truth,<br />
        Een Lak’esh, Ala K’ een / In Lak’esh, Ala K’in / In Lakesh, Ala Kin,<br />
        Thirteen Thank Yous,<br />
        Honey in the Heart,<br />
        Namasté,<br />
        Crispy Matters,<br />
        (Christopher Philip Madden).
    </p>
    </div>
    </div>  
    </div>  
    );
};

export default BlogDetail;
