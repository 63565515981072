import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BackgroundComponent from '../Components/bg';
import './styles.css'; // Import the CSS file

const Home = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
      const handleResize = () => {
          setIsLargeScreen(window.innerWidth >= 1024);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);
    return (
      <div className="min-h-screen">  
    
    <BackgroundComponent />
                
                <div className="absolute top-5 left-0 right-0 flex flex-col items-center">
        <div className="flex">
          <span className="block text-xl sm:text-2xl md:text-4xl lg:text-5xl xl:text-5xl font-bold font-matura text-pink-500 whitespace-nowrap outlined-cyan">
            Mayan Embodiment Is Soul Cartography
          </span>
        </div>
        {isLargeScreen ? (
        <div className="relative flex items-center justify-center" style={{ width: "1000px", height: "750px" }} >
        <img src="./mee.png" alt="Center Image" className=""style={{ width: "65%", height: "85%" }} />

        <Link to="/Readings">
    <button className="absolute top-5 left-5 bg-white text-blue-500 rounded-lg shadow-lg p-2 hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
        Readings / Blog
    </button>
</Link>
<Link to="/Calendar">
<button className="absolute top-5 right-5 bg-white text-blue-500 rounded-lg shadow-lg p-2 hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
    Check Dates
</button>
</Link>
<Link to="/workshop">
<button className="absolute bottom-5 left-5 bg-white text-blue-500 rounded-lg shadow-lg p-2 hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium  text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
    Shop
</button>
</Link>
<Link to="/Media">
<button type="button" className="absolute bottom-5 right-5 text-blue-700 bg-white hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
    Media
</button>
</Link>
    </div>
     ) : ( <div className=" top-12 left-0 right-0  ">
     <div className=" flex flex-col items-center justify-center mt-10 pt-10 l:pt-20 pb-10  text-md l:text-2xl mx-5 sm:mx-20 xl:mx-40 text-left pl-5 pr-5">
    
    <img src="./mee.png" alt="Center Image" className=""style={{ width: "65%", height: "85%" }} />
<div className="flex pt-2">
<Link to="/Readings">
<button className=" bg-white text-blue-500 rounded-lg shadow-lg p-2 hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
    Readings / Blog
</button>
</Link>
</div>
<div className="flex pt-2">
<Link to="/Calendar">
<button className=" bg-white text-blue-500 rounded-lg shadow-lg p-2 hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
Check Dates
</button>
</Link>
</div>
<div className="flex pt-2">
<Link to="/workshop">
<button className=" bg-white text-blue-500 rounded-lg shadow-lg p-2 hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium  text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
Shop
</button>
</Link>
</div>
<div className="flex pt-2">
<Link to="/Media">
<button type="button" className=" text-blue-700 bg-white hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
Media
</button>
</Link>
</div>
    </div>
    </div>
    
  )}
    <span className="block text-xl sm:text-2xl md:text-4xl lg:text-4xl xl:text-4xl font-bold font-matura text-white whitespace-nowrap outlined-pink">
            Tzolk' een is the Spiritual Codes of Life
          </span>
        </div>
        </div>
    
    
  );
};

export default Home;