import React, { useEffect } from 'react';
import BackgroundComponent from '../Components/bg';
import { Link } from 'react-router-dom';

const Media = () => {
  useEffect(() => {
    
    window.CustomSubstackWidget = {
      substackUrl: "crispymatters.substack.com",
      placeholder: "example@gmail.com",
      buttonText: "Subscribe",
      theme: "orange",
    };

    // Create the script element for the Substack widget
    const script = document.createElement('script');
    script.src = "https://substackapi.com/widget.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="min-h-screen relative">
      <BackgroundComponent />
      <div className="absolute top-5 left-0 right-0 flex flex-col items-center">
        <div className="flex">
          <span className="block outlined-green text-4xl  sm:text-3xl md:text-4xl lg:text-5xl xl:text-7xl font-bold font-matura text-pink-500 whitespace-nowrap">
          Media
          </span>
          
        </div> 
        </div> 
      <Link to="/">
        <button className="absolute z-50 top-5 left-5 bg-white text-blue-500 rounded-lg shadow-lg p-2 hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
        Home
        </button>
      </Link>
      <div className="absolute top-20 left-0 right-0 flex-shrink flex-col items-center">
        <div className="flex flex-col  mt-10 pt-10 l:pt-20 pb-10 bg-white text-md l:text-2xl mx-5 sm:mx-20 xl:mx-40 text-left pl-5 pr-5">
        <div className="flex justify-center pb-2 mt-10 ">
        <h1 className=" pb-5 block text-4xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-bold font-matura  whitespace-nowrap">
        Who is Crispy?
                </h1>
            </div>
          <h1> Born Christopher Philip Madden in Windsor, Ontario, Canada at 12:05am on December 27th, 1968, Crispy is K’ een 215, Ooq’ Men, Reflecting Eagle who currently lives in Japan, married to his Japanese wife, and father of two teenage children. He teaches English at university in Hiroshima part time, and does Mayan Embodiment full time. In 1998 he was first introduced to the Mayan calendar, and it instantly struck a chord with him, instantly ringing his bell, and that bell has never stopped ringing. In 1999 he traveled from Vancouver with his girlfriend (their home at that time), to Mexico, stopping to DJ at the Burning Man and do Mayan Astrology there on the way. Incidentally, it was the 13th Burning Man, and Crispy DJd at 4 installments, and was apparently the first Mayan Astrology booth at the Burn. Over the course of seven months in Mexico they saw thirteen different pyramid sites, including Fall Equinox at Teotihuacan, Winter Solstice at Palenque (thirteen years before the close of the Long Count on 12/21/2012, K’ een 160, Kan Ahau, Stabilizing Solar Chief), and Spring Equinox at Chichen Itza. Crispy also makes electronic music, and DJs, which he began in 1994 in Vancouver. Upon returning to Vancouver in April of 2000, he moved to The Sunshine Coast, a 45-minute ferry ride away from Vancouver, and continued DJing at Solstice, Equinox, Full Moon parties and other underground, private festivals, and dug deeper into his spiritual and psychedelic studies, including attending his first 10-day Vipassana meditation camp. He met his future wife there seven days later in April of 2001, K’ een 44, Ho Kan, Empowering Seed. His wife is K’ een 109, Ho Muluq’, Empowering Offering, and she took him very willfully to Japan in August of 2003. Here is the link to his electronic music page:</h1>
          <a href="https://chris-p-madden.bandcamp.com/"  target='blank' className="text-blue-500 underline ml-2">https://chris-p-madden.bandcamp.com/</a>
          <div className="flex flex-wrap  mt-2">
            <div className="w-full lg:w-1/2 ">
           
              <h1 className="mb-4 text-md l:text-2xl ">
                After studying the more advanced Mayan astrology system called "Mayan Destiny Path", and "Mayan Tree of Life" and "Mayan Cross" for many years, one day in the summer of 2018, Crispy was meditating at home, following the written guided meditation script of how to envision oneself inside the 260-day Tzol'kin gear grid. It was a meditation that appears in the book "Jaguar Wisdom" by Kenneth Johnson designed to orient a person to their birthday on the grid. Crispy had memorized the description, and was repeating it to himself silently. The book, and Kenneth's follow-up book, included a diagram like this. It was confusing, but Crispy had studied the Tzol'kin for almost two decades at that point, and wanted to fully understand this complex system.
              </h1>
              <h1 className="mb-4 text-md l:text-2xl ">
                Upon reaching a meditative state, and then starting to repeat the sentences to himself, he suddenly received a visceral vision! He saw himself inside the wheel of 20 Glyphs and Tones, and he literally FELT his right hand being tightly gripped by what he much later came to understand was his Divine Masculine Guide. At the same time, his head / attention / vision snapped over to that hand, and then looked back and up to his Past Wisdom Guide. Then he felt drawn to the gentle caress that his left hand was experiencing from his Divine Feminine Guide, so his eyes / attention / vision went there, and then drifted up to his future Vision Guide, all the while, feet firmly planted on his Core Self Mayan birthday. This whole vision lasted approximately one second, and was gone. The date of transmission is mysteriously unknown.
              </h1>
            </div>
            <div className="w-full lg:w-1/2  flex justify-center">
              <img src="mayancross.png"  className="xl:pl-40" style={{ maxHeight:"550px" }} />
            </div>
          </div>
          <h1 className="mb-4 text-md l:text-2xl">
            He was absolutely blown away. Crispy is not a visual meditator. He had been to two 10-day Vipassana meditation camps by then, and listened to hundreds of hours of "guided meditations" on YouTube and in the Gaia-TV library, and had NEVER received any visual representation while meditating.
          </h1>
          <div className=" bg-white text-black ">
          <div className="flex justify-center">
              <img src="empowerment.png"  className="mx-5 max-w-full h-80" />
            </div>
            <h1 className="mb-4 text-md l:text-2xl">
              This was obviously a massive download, which is now called the "Empowerment Circle" on this website and in all the Readings that Crispy gives. It took him a very long time to draw this, because he is NOT an artist, but this is as close a representation as can be shown, keeping in mind that this is NOT what Crispy saw, because he was not looking at himself from afar, as this image portrays.
            </h1>
            <h1 className="mb-4 text-md l:text-2xl">
              Over the ensuing years, and after researching every single English book and website on the topic, and subsequently becoming convinced that the Mayan Calendar came from the Pleiades, Crispy created his own versions of the Glyphs, with his own translations. He also had the vision of the Tones being connected directly to the Chakras when laid out in the thirteen-day pyramid structure that he came to call the Pulse-Wave, seen here. He had known since 2001 that the Maya knew about the Chakras,
            </h1>
            <div className="flex justify-center">
              <img src="pyramid.png"  className="mx-5 max-w-full h-80" />
            </div>
            <h1 className="mb-4 text-md l:text-2xl">
              and Kundalini, when he studied with Hunbatz Men and bought his first book, "Secrets of Mayan Science / Religion". Synchronously, there were 13 people to join the workshop that day in August of 2001, on K’een 184, which is 2 Kan, "Pairing Seed", and here is a picture.
            </h1>
            <div className="flex justify-center">
              <img src="group.jpg"  className="mx-5 max-w-full h-80" />
            </div>
            <h1 className="mb-4 text-md l:text-2xl">
              Please follow Crispy at his Facebook page where he gives daily readings and so much more:
            </h1>
            <h1 className="mb-4 text-md l:text-2xl break-words">
              <a href="https://www.facebook.com/MayanEmbodimentAstrology"  target='blank'className="text-blue-500 hover:underline">
                https://www.facebook.com/MayanEmbodimentAstrology
              </a>
            </h1>
            <h1 className="mb-4 text-md l:text-2xl">
              Here is Crispy's YouTube channel with dozens and dozens of Mayan Embodiment videos, and if you like electronic music, be sure to check out the "Radio Crispy" playlist:
            </h1>
            <h1 className="mb-4 text-md l:text-2xl break-words">
              <a href="https://www.youtube.com/@Mayan.Embodiment"  target='blank' className="text-blue-500 hover:underline">
              https://www.youtube.com/@Mayan.Embodiment
              </a>
            </h1>
            <h1 className="mb-4 text-md l:text-2xl">
              Find Crispy on instagram with the link below
            </h1>
            <h1 className="mb-4 text-md l:text-2xl break-words">
              <a href="https://www.instagram.com/mayan_embodiment/" target='blank' className="text-blue-500 hover:underline">
              https://www.instagram.com/mayan_embodiment/
              </a>
            </h1>
            <h1 className="mb-4 text-md l:text-2xl">
              If you would like to contact Crispy directly, you could also email him:
            </h1>
            <h1 className="mb-4 text-md l:text-2xl break-words ">
              <a href="mailto:mayan.embodiment.astrology@gmail.com" target='blank' className="text-blue-500 hover:underline">
              mayan.embodiment.astrology@gmail.com
              </a>
            </h1>
            <p className=" text-md lg:text-2xl">
              Een Lak’esh, Ala K’ een,              
            </p>
            <p className=" text-md lg:text-2xl">I am You  are God-sciousness, </p>
            <p className=" text-md lg:text-2xl">Thirteen Thank Yous, </p>
            <p className="text-md lg:text-2xl">Honey in the Heart, </p>
            <p className="text-md lg:text-2xl">Namaste, </p>
            <p className="text-md lg:text-2xl">Crispy </p>
          
            <h1 className='flex justify-center font-bold m-2 '>Sign up for the newsleter today </h1>
            <div className="flex justify-center  pt-3">
              
            <iframe 
  src="https://crispymatters.substack.com/embed" 
  width="480" 
  height="150" 
  style={{ border: '1px solid #EEE', background: 'white' }} 
  frameBorder="0" 
  scrolling="no">
</iframe>

            </div>
            <div className="flex justify-center pb-2 mt-10 ">
            <h1 className=" pb-5 block text-4xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-bold font-matura  whitespace-nowrap">
            Friends of Embodiment
                </h1>
                </div>
                <h1 className="mb-4 text-md lg:text-md">
                For over 20 years, Crispy has relied on the stellar information that originally came through Ian Xel Lungold which became Mayan Majix. After Ian crossed the Rainbow Bridge to Xibalba, the site has been run by Michael Shore, a friend to all those interested in anything connected to the Maya. I did Michael’s Embodiment reading, and here are some of the things he had to say about it: “Over all, I was impressed with the depth and level of detail. I felt it was accurate and gave me some new insights to consider. One thing for sure is your passion for helping people shines thru. The report and workbooklet are well organized, professionally written, I liked the use of color highlights, the personal touch gave me a very supportive / caring feeling, I particularly appreciate your personal comments and suggestions which are supportive and helpful. Your sincerity shines thru and I appreciate it.”
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                Michael, K’een 79, Initiating Rainstorm, also suggested to me that I make a Mayan Embodiment video for Ian Lungold, K’een 220, Understanding Solar Chief, and he supplied me with many personal pictures to do so, which can be seen here;
                <a href="https://www.youtube.com/watch?v=cHNAU72-AB8" target='blank' className="text-blue-500 underline ml-2">https://www.youtube.com/watch?v=cHNAU72-AB8</a>
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                Mayan Majix has not only a brilliant website with a great birthday translator, but an “Articles” page with dozens and dozens of links in many esoteric categories. Here’s one article I wrote for the site a few years ago;
                <a href="https://www.mayanmajix.com/wp/break-the-spell/" target='blank' className="text-blue-500 underline ml-2">https://www.mayanmajix.com/wp/break-the-spell/</a>
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                For the serious student of all things Mayan, the name Dr. Carl Johan Calleman is very well known. Dr. Calleman, K’een 174, Empowering Jaguar-Shaman, has written many books about the Mayan culture and calendar, and he cracked the code on the way that the Mayan calendar describes the evolution of human consciousness extremely precisely. He also allowed me to use some of the precious interview footage he did with don Alejandro Cirilo Perez Oxlaj in the Embodiment video I made for him here;
                <a href="https://www.youtube.com/watch?v=nAcXN4QBQYk&t=991s" target='blank' className="text-blue-500 underline ml-2">https://www.youtube.com/watch?v=nAcXN4QBQYk&t=991s</a>
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                To learn more about Dr. Calleman’s work, please see his website;
                <a href="https://calleman.com/" target='blank' className="text-blue-500 underline ml-2">https://calleman.com/</a>
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                Fascinatingly, Mark Elmy, one of the few Westerners to have been granted full Mayan Calendar Day Keeper status from the authentic Guatemalan Mayan Elders, has the exact same Mayan birthday as Dr. Calleman. Mark, however, is much more down to earth. His videos on the Trecenas are spectacular, as are the others he posts, and can be seen here;
                <a href="https://www.youtube.com/@markelmy8770" target='blank' className="text-blue-500 underline ml-2">https://www.youtube.com/@markelmy8770</a>
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                And Mark’s website is here;
                <a href="https://thefourpillars.net/" target='blank' className="text-blue-500 underline ml-2">https://thefourpillars.net/</a>
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                A wonderful resource for daily readings is Kenneth Johnson, whose books “Jaguar Wisdom” and “Mayan Calendar Astrology: Mapping your Inner Cosmos” have been instrumental for Crispy and tens of thousands of others. You can follow him here;
                <a href="https://www.facebook.com/jaguar.wisdom  " target='blank' className="text-blue-500 underline ml-2">https://www.facebook.com/jaguar.wisdom </a>
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                For those who wish to be completely up to date on the ongoing excavations in Mexico, Belize, Guatemala, Honduras, and Costa Rica, this YouTube channel connects you to top level academic research and presentations:
                <a href="https://www.youtube.com/@aztlander7143" target='blank' className="text-blue-500 underline ml-2">https://www.youtube.com/@aztlander7143</a>
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                And for those who like to have a slightly more personal approach to a similar research-based field of interviews, Dr. Ed Barnhart, K’een 156, Transcending Wise One, please know that Ed was the first person to accurately map the whole pyramid city of Lakam-Ha (later called Palenque by the Spaniards). He knows everything about it and tons of other shamanic facts, too. He also conducts tours to Mayan pyramids, and you can check his videos out here:
                <a href="https://www.youtube.com/@archaeoedpodcast" target='blank' className="text-blue-500 underline ml-2">https://www.youtube.com/@archaeoedpodcast</a>
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                Now if you would like to know the actual Long Count Mayan information for any date in the last 2000 years, you can use Ed’s website here:
                <a href="https://www.barsanddots.com" target='blank' className="text-blue-500 underline ml-2">https://www.barsanddots.com</a>
                or his other one here, which has links to his own phone app, too:
                <a href="https://www.mayan-calendar.com" target='blank' className="text-blue-500 underline ml-2">https://www.mayan-calendar.com</a>
                These are just the types of things that a Transcending Wise One would create, wouldn’t you agree?
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                Although she too has been paddled across the river to the Underworld of Xibalba, Barbara Sadtler’s website,
                <a href="https://www.mayancross.com/" target='blank' className="text-blue-500 underline ml-2">https://www.mayancross.com/</a>
                is still being maintained as yet another great resource. Crispy had many communications with her, and consulted with her on the creation of the “Compatibilities” of her website. Barbara was K’een 258, Resolving Obsidian Mirror / Knife, and has the same Mayan birthday as Miles Davis & Billy Carson & Geraldine Ferraro & Richard Rudd, by the way.
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                You will always find delightful things from Eva Marquez, K’een 75, Manifesting Eagle, on her YouTube Channel and elsewhere:
                <a href="https://www.youtube.com/@EvalisticHealing" target='blank' className="text-blue-500 underline ml-2">https://www.youtube.com/@EvalisticHealing</a>
                She has written many books and is a well known Pleiadian channel who, after I did her reading, received a lucid dream where Mayan elders told her they approve of my work.
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                For a glimpse into the ever-expanding world of cosmic information, Neil Gaur (K’een 88, Manifesting Starseed - his Embodiment video is on my YouTube Channel) created the Portal to Ascension YouTube channel,
                <a href="https://www.bit.ly/4cugyZM, " target='blank' className="text-blue-500 underline ml-2">https://www.youtube.com/@PortalToAscension , </a>
                to go along with his website,
                <a href="https://portaltoascension.org/" target='blank' className="text-blue-500 underline ml-2">https://portaltoascension.org/</a>
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                Crispy’s personal friends, Yssah Arzate Libien and Sean Bond are a powerful pair of higher consciousness teachers and guides. Yssah, K’een 66, Initiating Reincarnation, is a polymath, cosmically brilliant, and has lead many tours to both the Mayan and Egyptian pyramids. She decodes Ancient technology as quantum vacuum plasma tech focalized in restoring the physical human body to its original potential as a cosmic race, all through spiritual science, which she has named Cosmic Ontogenetics, Unified Consciousness, and Holofractographic Neuroscience. Her website is
                <a href="https://hunuh-akhna.com/"  target='blank'className="text-blue-500 underline ml-2">https://hunuh-akhna.com/</a>
                and her YouTube channel is:
                <a href=" https://www.youtube.com/@yssahassy_unifiedconsciousness"  target='blank'className="text-blue-500 underline ml-2"> https://www.youtube.com/@yssahassy_unifiedconsciousness</a>
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                Sean, K’een 185, Activating Serpent, is a shaman capable of changing the weather, both externally and inside the hearts and minds of his clients. Sean is the founder of Psionic League and Silver Cord Spiritual Science. He is a spiritual healer who aids others in holistic healing and spiritual growth, teaching them about multidimensional awareness and interaction with all their individual DNA technologies, higher functions and operating systems. His website is here:
                <a href="https://psionicleague.com/" target='blank' className="text-blue-500 underline ml-2">https://psionicleague.com/</a>
                and his YouTube channel is here:
                <a href="https://www.youtube.com/@SilverCordSpiritualScience" target='blank' className="text-blue-500 underline ml-2">https://www.youtube.com/@SilverCordSpiritualScience</a>
            </h1>
            <h1 className="mb-4 text-md lg:text-md break-words">
                This website was created by the brilliant Landseer Enqa, K’een 139, Patient Rainstorm, and he is also the creator of:
                <a href="https://www.cognisim.io/" target='blank' className="text-blue-500 underline ml-2">https://www.cognisim.io/</a>
                and can be reached at : 
                <a href="mailto: landseerenga@gmail.com" target='blank' className="text-blue-500 hover:underline">
                 landseerenga@gmail.com
              </a>
            </h1>
            </div>
            </div>
            </div>
    </div>
  );
};

export default Media;