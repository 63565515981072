import axios from 'axios';
import React, { useState, useEffect } from 'react';
import BackgroundComponent from '../Components/bg';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import locales from '../Components/locales'

const Calendar = () => {
    const [imageUrls, setImageUrls] = useState({ days: '', davinci: '', core: '' });
    const [isSubmitted, setIsSubmitted] = useState(false);  
    const [imageState, setImageState] = useState(0);  
    const today = new Date();
    const formattedToday = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
    const [selectedDate, setSelectedDate] = useState(today);
    const [userLocale, setUserLocale] = useState('en-US');
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const fetchImage = (date) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        axios.post(apiUrl, {
            date: date
        })
        .then(response => {
            const responseBody = JSON.parse(response.data.body);
            setImageUrls({
                days: responseBody.days || null,
                davinci: responseBody.davinci || null,
                core: responseBody.core || null,
                key: responseBody.key || null  // Add the new key field
            });
        })
        .catch(error => {
            console.error('Error fetching image:', error);
            setImageUrls({ days: null, davinci: null, core: null, key: null });  // Handle the new key field in error case
        });
    };
    useEffect(() => {
        const locale = navigator.language || 'en-US';
        console.log(formattedToday)
        console.log(today)
        fetchImage(formattedToday);
        setUserLocale(locale);
    }, []);
    const handleSubmit = () => {
        if (selectedDate) {
            const formattedDate = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`;
            fetchImage(formattedDate);
            setIsSubmitted(true);  // Set isSubmitted to true on successful submit
            setImageState(0);  // Set imageState to 1 on submit
        } else {
            alert('Please select a date.');
        }
    };
    const handleFlip = () => {
        setImageState((prevState) => (prevState + 1) % 4);  // Cycle through 0, 1, 2, 3
    };

    if (isSubmitted) {
        return (
            <div className="absolute pt-20 lg:pt-5 top-5 left-0 right-0 flex flex-col items-center h-screen ">
                <Link to="/">
                    <button className="absolute z-50 top-5 left-5 bg-white text-blue-500 rounded-lg shadow-lg p-2 hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                    Home
                    </button>
                </Link> 
                
                <div>
                <BackgroundComponent />
                    
                <div >
                        
                    <div className="flex flex-col items-center " style={{ flexShrink: 1, width: '100%', height: '100%' }}> 
                    {imageState === 0 && imageUrls.days && ( <div className="flex flex-col items-center" style={{ flexShrink: 1, width: '100%', height: '100%' }}>
                    <span className="block text-2xl sm:text-2xl md:text-5xl lg:text-6xl xl:text-6xl font-bold font-matura text-green-500 whitespace-nowrap pb-10 outlined-green ">
                            This is your Empowerment Circle
                        </span>
                        <img src={imageUrls.days} alt="Days Image"  style={{ width: "57%",  hight: "40%", maxHeight:"900px", minHeight:"200px",minWidth:"400px" }} />
                        <div className='pt-2'>
                        <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="yyyy-MM-dd"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholderText="Select date"
                locale={locales[userLocale]}  // Set the locale dynamically
                popperPlacement="top-start"  // Make the calendar appear above
            />
            <button 
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold shadow-lg rounded-lg py-2 px-4"
                    onClick={handleSubmit}
                >
                    Submit
                </button> 
                </div>
                
                <div className="flex space-x-4">
                <div className="flex justify-center items-center w-full h-full">
                <div className="flex justify-center items-center w-full">
                    <button 
                        className="m-2 z-50 bg-white text-blue-700 rounded-lg shadow-lg p-4 hover:text-white border-2 border-sky-500 hover:bg-blue-800 font-medium text-xl px-8 py-4 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500"
                        onClick={handleFlip}
                    >
                        {imageState === 0 && imageUrls.days && <span>Click for DaVinci Codex</span>}
                        {imageState === 1 && imageUrls.davinci && <span>Click for Core Self Codes</span>}
                        {imageState === 2 && imageUrls.core && <span>Click for your Full Key Codes</span>}
                        {imageState === 3 && imageUrls.key && <span>Click for Empowerment Circle</span>}
                    </button>
                </div>
                </div>
                        
                    </div>
                    <span className="block text-md sm:text-2xl md:text-5xl lg:text-5xl xl:text-5xl font-bold font-matura text-pink-500 whitespace-nowrap outlined-pink pt-3">
                            Tzolk' een is the Spiritual Codes of Life
                        </span>
                    </div>)}
                    {imageState === 1 && imageUrls.davinci && ( <div className="flex flex-col items-center" style={{ flexShrink: 1, width: '100%', height: '100%' }}>
                    <span className="block text-2xl sm:text-2xl md:text-5xl lg:text-6xl xl:text-6xl font-bold font-matura text-green-500 whitespace-nowrap pb-10 outlined-green ">
                        This is your DaVinci Codex
                        </span>
                        <img src={imageUrls.davinci} alt="Da Vinci Image"  style={{ width: "57%",  hight: "40%", maxHeight:"900px", minHeight:"200px",minWidth:"400px" }} />
                        <div className='pt-2'>
                <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="yyyy-MM-dd"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholderText="Select date"
                    popperPlacement="top-start"
                />
                 <button 
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold shadow-lg rounded-lg py-2 px-4"
                    onClick={handleSubmit}
                >
                    Submit
                </button> 
                </div>
                
                <div className="flex justify-center items-center w-full">
                    <button 
                        className="m-2 z-50 bg-white text-blue-700 rounded-lg shadow-lg p-4 hover:text-white border-2 border-sky-500 hover:bg-blue-800 font-medium text-xl px-8 py-4 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500"
                        onClick={handleFlip}
                    >
                       {imageState === 0 && imageUrls.days && <span>Click for DaVinci Codex</span>}
                        {imageState === 1 && imageUrls.davinci && <span>Click for Core Self Codes</span>}
                        {imageState === 2 && imageUrls.core && <span>Click for your Full Key Codes</span>}
                        {imageState === 3 && imageUrls.key && <span>Click for Empowerment Circle</span>}
                    </button>
                </div>
                    <span className="block text-md sm:text-2xl md:text-5xl lg:text-5xl xl:text-5xl font-bold font-matura text-pink-500 whitespace-nowrap outlined-pink pt-3">
                            Tzolk' een is the Spiritual Codes of Life
                        </span>
                    </div>)}
                    {imageState === 2 && imageUrls.core && 
                    ( <div className="flex flex-col items-center" style={{ flexShrink: 1, width: '100%', height: '100%' }}>
                       <span className="block text-2xl sm:text-2xl md:text-5xl lg:text-6xl xl:text-6xl font-bold font-matura text-green-500 whitespace-nowrap pb-10 outlined-green ">
                            These are your Core Self Codes
                        </span>
                        <img src={imageUrls.core} alt="Core Image"  style={{ width: "57%",  hight: "40%", maxHeight:"900px", minHeight:"200px",minWidth:"400px" }} />
                        <div className='pt-2 z-50'>
                <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="yyyy-MM-dd"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholderText="Select date"
                    popperPlacement="top-start"
                />
                <button 
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold shadow-lg rounded-lg py-2 px-4"
                    onClick={handleSubmit}
                >
                    Submit
                </button> 
                </div>
                
                <div className="flex justify-center items-center w-full">
                    <button 
                        className="m-2 z-50 bg-white text-blue-700 rounded-lg shadow-lg p-4 hover:text-white border-2 border-sky-500 hover:bg-blue-800 font-medium text-xl px-8 py-4 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500"
                        onClick={handleFlip}
                    >
                       {imageState === 0 && imageUrls.days && <span>Click for DaVinci Codex</span>}
                        {imageState === 1 && imageUrls.davinci && <span>Click for Core Self Codes</span>}
                        {imageState === 2 && imageUrls.core && <span>Click for your Full Key Codes</span>}
                        {imageState === 3 && imageUrls.key && <span>Click for Empowerment Circle</span>}
                    </button>
                </div>
                        <span className="block text-md sm:text-2xl md:text-5xl lg:text-5xl xl:text-5xl font-bold font-matura text-pink-500 whitespace-nowrap outlined-pink pt-3">
                            Tzolk' een is the Spiritual Codes of Life
                        </span>
                    </div>)}
                    {imageState === 3 && imageUrls.key && (
    <div className="flex flex-col items-center" style={{ flexShrink: 1, width: '100%', height: '100%' }}>
        <span className="block text-2xl sm:text-2xl md:text-5xl lg:text-6xl xl:text-6xl font-bold font-matura text-green-500 whitespace-nowrap pb-10 outlined-green ">
            These are your Full Key Codes
        </span>
        <img src={imageUrls.key} alt="Key Image" style={{ width: "57%", height: "40%", maxHeight:"900px", minHeight:"200px", minWidth:"400px" }} />
        <div className='pt-2'>
            <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="yyyy-MM-dd"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholderText="Select date"
                locale={locales[userLocale]}
                popperPlacement="top-start"
            />
            <button 
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold shadow-lg rounded-lg py-2 px-4"
                onClick={handleSubmit}
            >
                Submit
            </button> 
        </div>
        
        <div className="flex justify-center items-center w-full">
            <button 
                className="m-2 z-50 bg-white text-blue-700 rounded-lg shadow-lg p-4 hover:text-white border-2 border-sky-500 hover:bg-blue-800 font-medium text-xl px-8 py-4 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500"
                onClick={handleFlip}
            >
                {imageState === 0 && imageUrls.days && <span>Click for DaVinci Codex</span>}
                {imageState === 1 && imageUrls.davinci && <span>Click for Core Self Codes</span>}
                {imageState === 2 && imageUrls.core && <span>Click for your Full Key Codes</span>}
                {imageState === 3 && imageUrls.key && <span>Click for Empowerment Circle</span>}
            </button>
        </div>
        <span className="block text-md sm:text-2xl md:text-5xl lg:text-5xl xl:text-5xl font-bold font-matura text-pink-500 whitespace-nowrap outlined-pink pt-3">
            Tzolk' een is the Spiritual Codes of Life
        </span>
    </div>
)}
                </div>
                <div className=" p-4">
               
                    </div>
                    </div>
                  
                </div>
                
                </div>
        );  
    }

    return (
        <div>
           
           
           
            <Link to="/">
                <button className="absolute z-50 top-5 left-5 bg-white text-blue-500 rounded-lg shadow-lg p-2 hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                Home
                </button>
            </Link> 
            <div>
            
            <BackgroundComponent />
            <div className="absolute pt-20 lg:pt-5 top-5 left-0 right-0 flex flex-col items-center h-screen ">
                <div className="flex flex-col items-center">
                {imageState === 0 && imageUrls.days && <span className="block text-2xl sm:text-2xl md:text-5xl lg:text-6xl xl:text-6xl font-bold font-matura text-green-500 whitespace-nowrap pb-10 outlined-green ">
                        What is your Mayan Embodiment?
                    </span>}
                {imageState === 1 && imageUrls.davinci &&  <span className="block text-2xl sm:text-2xl md:text-5xl lg:text-6xl xl:text-6xl font-bold font-matura text-green-500 whitespace-nowrap pb-10 outlined-green ">
                        This is your DaVinci Codex
                        </span>}
                {imageState === 2 && imageUrls.core &&  <span className="block text-2xl sm:text-2xl md:text-5xl lg:text-6xl xl:text-6xl font-bold font-matura text-green-500 whitespace-nowrap pb-10 outlined-green ">
                            These are your Core Self Codes
                        </span>}
                 {imageState === 3 && imageUrls.key &&  <span className="block text-2xl sm:text-2xl md:text-5xl lg:text-6xl xl:text-6xl font-bold font-matura text-green-500 whitespace-nowrap pb-10 outlined-green ">
                            These are your Full Key Codes
                        </span>}
                    {imageState === 0 && imageUrls.days && <img src={imageUrls.days} alt="Days Image" style={{ width: "57%",  hight: "50%", maxHeight:"900px", minHeight:"200px", minWidth:"400px" }} />}
                    {imageState === 1 && imageUrls.davinci && <img src={imageUrls.davinci} alt="Da Vinci Image" style={{ width: "57%",  hight: "50%", maxHeight:"900px", minHeight:"200px", minWidth:"400px" }} />}
                    {imageState === 2 && imageUrls.core &&  <img src={imageUrls.core} alt="Core Image" style={{ width: "57%",  hight: "50%", maxHeight:"900px", minHeight:"200px",minWidth:"400px" }} />}
                    {imageState === 3 && imageUrls.key &&  <img src={imageUrls.key} alt="Key Image" style={{ width: "57%",  hight: "50%", maxHeight:"900px", minHeight:"200px",minWidth:"400px" }} />}
                </div>
                <div flex className='pt-2'>
                <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholderText="Select date"
                        popperPlacement="top-start"
                        onCalendarOpen={() => setIsDatePickerOpen(true)}
                        onCalendarClose={() => setIsDatePickerOpen(false)}
                    />
                    <button 
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold shadow-lg rounded-lg py-2 px-4"
                    onClick={handleSubmit}
                >
                    Submit
                </button> 
                </div>
                <div className='pt-2 pb-2'>
                
                </div>
                <div className="flex justify-center items-center w-full">
                    <button 
                        className="m-2 z-50 bg-white text-blue-700 rounded-lg shadow-lg p-4 hover:text-white border-2 border-sky-500 hover:bg-blue-800 font-medium text-xl px-8 py-4 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500"
                        onClick={handleFlip}
                    >
                        {imageState === 0 && imageUrls.days && <span>Click for DaVinci Codex</span>}
                        {imageState === 1 && imageUrls.davinci && <span>Click for Core Self Codes</span>}
                        {imageState === 2 && imageUrls.core && <span>Click for your Full Key Codes</span>}
                        {imageState === 3 && imageUrls.key && <span>Click for Empowerment Circle</span>}
                    </button>
                </div>
                <span className="block text-md sm:text-2xl md:text-5xl lg:text-5xl xl:text-6xl font-bold font-matura text-pink-500 whitespace-nowrap outlined-pink pt-3">
                    Tzolk' een is the Spiritual Codes of Life
                </span>
               
                </div>
            </div>
            
        </div>
    );
};

export default Calendar; 