import React from 'react';

const SingleCard = ({ title, description, imageUrl, author, timeAgo }) => {
  return (
    <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl my-4">
      <div className="md:flex">
        
        <div className="p-8">
          
          <a  className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">{title}</a>
          <p className="mt-2 text-gray-500">{description}</p>
          
        </div>
      </div>
    </div>
  );
};

export default SingleCard;