import React, { useEffect } from 'react';
import BackgroundComponent from '../Components/bg';
import { Link } from 'react-router-dom';
import './styles.css'; 
import SingleCard from '../Components/SingleCard';
const Readings = () => {
    useEffect(() => {
        // Define the Substack widget configuration
        window.SubstackFeedWidget = {
            substackUrl: "crispymatters.substack.com",
            posts: 50
        };

        // Create the script element for the Substack feed
        const script = document.createElement('script');
        script.src = 'https://substackapi.com/embeds/feed.js';
        script.async = true;
        document.body.appendChild(script);

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="min-h-screen relative">  
            <BackgroundComponent />
            <Link to="/">
                <button className="absolute z-50 top-5 left-5 bg-white text-blue-500 rounded-lg shadow-lg p-2 hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                Home
                </button>
            </Link>
            <div className="absolute top-5 left-0 right-0 flex flex-col items-center">
                <span className="block  outlined-green text-4xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-bold font-matura text-pink-500 whitespace-nowrap">
                    Readings
                </span>
            </div>
            <div className='pt-20'>
            <Link to="/Read">
                <SingleCard
                    title="Readings"
                    description="Greetings, dear Soul! Mayan Embodiment offers you four varieties of 
                    readings. The first is called Core Self Reading, and costs $13.20 in 
                    American.."
                    
                />
            </Link>
            <Link to="/Testimonials">
          <SingleCard
          title="Testimonials"
          description="Here you can read many people's experience of cripsy's work"
          
          
        />
        </Link>
        </div>
       
            <div className="inset-0 flex flex-col justify-center items-center pt-5">  
            <span className=" pb-5 block text-4xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-bold font-matura text-white whitespace-nowrap">
                    Check out the blog
                </span>
                <div id="substack-feed-embed"></div>
            </div>            
        </div>
    );
};

export default Readings;