import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home'; // Make sure the component name is capitalized
import Readings from './Pages/readings';
import Media from './Pages/media';
import Calendar from './Pages/calendar';
import Workshop from './Pages/workshop';
import BlogDetail from './Pages/BlogDetail';
import Testimonials from './Pages/testimonials';
function App() {
  return (
    
    <Routes> 
      <Route path="/" element={<Home />} />
      <Route path="/Readings" element={<Readings />} />
      <Route path="/Media" element={<Media />} />
      <Route path="/Calendar" element={<Calendar/>} />
      <Route path="/Read" element={<BlogDetail/>} /> 
      <Route path="/Workshop" element={<Workshop/>} />
      <Route path="Testimonials" element={<Testimonials/>} />
    </Routes>
    
  );
}

export default App;