import React from 'react';

const BackgroundComponent = () => {
  return (
    <div className="bg-cover bg-center fixed top-0 left-0 w-full h-full z-[-1]" style={{ 
      backgroundImage: "url('/bg.jpg')",
      backgroundSize: 'cover',
      backgroundRepeat: 'repeat',
      backgroundAttachment: 'fixed'
    }}>
      <div className="flex justify-between items-center h-full lg:flex hidden">
      <div className="flex items-center h-full"> 
          <img className="bg-cover bg-center w-16" src={`${process.env.PUBLIC_URL}/Sidebar.png`} alt="" style={{ height: '100%' }} />
          <img className="bg-cover bg-center w-16 ml-4 opacity-60" src={`${process.env.PUBLIC_URL}/Sidebar.png`} alt="" style={{ height: '100%' }} />
          <img className="bg-cover bg-center w-16 ml-4 opacity-30" src={`${process.env.PUBLIC_URL}/Sidebar.png`} alt="" style={{ height: '100%' }} />
        </div>
        <div className="flex justify-end h-full">
          <img className="bg-cover bg-center w-16 mr-4 opacity-30" src={`${process.env.PUBLIC_URL}/Sidebar.png`} alt="" style={{ height: '100%' }} />
          <img className="bg-cover bg-center w -16 mr-4 opacity-60" src={`${process.env.PUBLIC_URL}/Sidebar.png`} alt="" style={{ height: '100%' }} />
          <img className="bg-cover bg-center w-16" src={`${process.env.PUBLIC_URL}/Sidebar.png`} alt="" style={{ height: '100%' }} />
        </div>
      </div>
    </div>
  );
};

export default BackgroundComponent;