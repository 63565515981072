import React from 'react';
import BackgroundComponent from '../Components/bg';
import { Link } from 'react-router-dom';
const Testimonials = () => {
    
   
    return (
        <div className="min-h-screen relative">  
        <BackgroundComponent />
            <Link to="/Readings">
                <button className="absolute  z-50 top-5 left-5 bg-white text-blue-500 rounded-lg shadow-lg p-2 hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                    Back
                </button>
                </Link>
                <div className="absolute top-5 left-0 right-0 flex flex-col items-center">
        <div className="flex">
          <span className="block outlined-green text-4xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-7xl font-bold font-matura text-pink-500 whitespace-nowrap">
          Testimonials
          </span>
          
        </div> 
        <div className="inset-0 flex flex-col mt-10 items-center  pt-10 pb-5  mx-5 sm:mx-20 xl:mx-40 text-left md:pr-20 md:pl-20 bg-slate-200 ">
            <iframe 
            title="Facebook post by Rey Brannen" // Add a descriptive title
            src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Frey.brannen%2Fposts%2Fpfbid02yvac95HJ7VZzjrqPgmFnpE2ctu6146xDox2GRzVgxAHsaMyYyL2kAQXXSW34N97jl&show_text=true&width=500" 
            width="500" 
            height="272" 
            style={{ border: 'none', overflow: 'hidden' }} 
            scrolling="no" 
            frameBorder="0" 
            allowFullScreen={true} 
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
            </iframe>
            <div className="pb-2 pt-2">
            <iframe 
            title="Facebook post by Rey Brannen" // Add a descriptive title
            src= "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmayan.missions.58%2Fposts%2Fpfbid023E8ocYM44oGfcrLqRiP9x3HTtTkEq34tPusRKvXF4nyffhwVYF12xMFE9ks75VYkl&show_text=true&width=500"
            width="500" 
            height="272" 
            style={{ border: 'none', overflow: 'hidden' }} 
            scrolling="no" 
            frameBorder="0" 
            allowFullScreen={true} 
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
            </iframe>
            </div>
            <iframe 
                title="Facebook post by Rey Brannen" // Add a descriptive title
                src= "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FKarlAntD%2Fposts%2Fpfbid02FzFat2BhdPWWn4M7kyRz4ymcKs7tsKMkioCNo2YLpgxxFYQmfDhuhh7yVty95snGl&show_text=true&width=500" 
                width="500" 
                height="272" 
                style={{ border: 'none', overflow: 'hidden' }} 
                scrolling="no" 
                frameBorder="0" 
                allowFullScreen={true} 
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
               
            </iframe>
            
          <a href="https://www.facebook.com/Mayan.Embodiment.Astrology/reviews" target="_blank" rel="noopener noreferrer">
                        <button type="button" className=" m-3 text-white bg-blue-600 hover:text-white border-2 border-sky-500 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                          Click here to view more 
                        </button>
                    </a>
        </div>
        
        </div> 
            
        </div>
    );
};

export default Testimonials;
